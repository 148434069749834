.app {
  min-height: 100% !important;
}
.app .header {
  line-height: 64px;
  border-bottom: 1px solid #e8e8e8;
  background: #fff;
}
.app .header .logo {
  width: 120px;
  height: 31px;
  margin: 16px 28px 16px 0;
  float: left;
}
.app .logout {
  display: flex;
  align-items: center;
  height: 100%;
  float: right;
}
.app .navigation {
  line-height: 62px;
}
.app .footer {
  text-align: center;
}

.app .wrapper {
  margin-top: 25px;
  padding: 0 50px;
}
.app .layout {
  padding: 24px 0;
  background: #fff;
}
.app .content {
  padding: 0 24px;
  min-height: 280px;
}
.app .content_body {
  background: #fff;
}
.app .content .heading a {
  width: fit-content;
}

/* Hide the warning text for a given field in an Ant Design form */
.no-explain .ant-form-explain {
  display: none;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.17rem;
}