.container .info_icon {
  margin-right: 10px;
}
.container .ant-select-selection--multiple {
  max-height: 100px;
  overflow: auto;
}
.container .container_list .header_buttons {
  float: right;
  margin-right: 10px;
  margin-top: -5px;
}
.container .container_list .header_buttons button {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  margin-right: 10px;
}
.container .container_list .tab {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.container .container_list .filter_wrapper {
  width: 100%;
}
.container .container_list .filter {
  max-width: 500px;
  margin-bottom: 10px;
  display: flex;
}
.container .container_list .filter i {
  cursor: pointer;
}
.container .container_list .item {
  width: 175px;
  min-height: 175px;
  margin-right: 15px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.container .container_list .add {
  align-items: center;
  justify-content: center;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  background-color: #fafafa;
  cursor: pointer;
  padding: 15px;
  box-sizing: border-box;
  transition: border 0.5s;
}
.container .container_list .add i {
  font-size: 32px;
  color: #999;
}
.container .container_list .add span {
  margin-top: 10px;
  color: #666;
}
.container .container_list .add:hover {
  border-color: #1890ff;
}
.container .container_list .clear {
  float: right;
}
.container .container_list .shared {
  color: #9e9e9e;
  margin-right: 10px;
}

.container .container_list .filter .ant-input-group input {
  border-radius: 0;
}

.datalab-drawer .ant-drawer-content-wrapper {
  width: 80% !important;
  max-width: 800px;
}
.ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
  background: #EEEEEE;
}

.container_list .cards .ant-row {
  display: flex;
  flex-wrap: wrap;
}