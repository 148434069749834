@import '~antd/dist/antd.css';

* {
  box-sizing: border-box;
  outline: 0;
}

body {
  margin: 0;
  padding: 0;
}

body, #root {
  height: 100%;
  width: 100%;
}

/* Border bug fix for ant design sider */
.ant-layout-sider {
  background: none;
}
.ant-layout-sider-children {
  margin-top: 0;
}

/* Capitalize drop-down menu headers */
.ant-select-dropdown-menu-item-group-title {
  color: #1890ff;
}