.checkbox-group {
  display: flex;
}
.checkbox-group > div {
  user-select: none; 
  background: #fff;
  border: 1px solid #d9d9d9;
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s;
}
.checkbox-group.disabled > div {
  cursor: default;
}
.checkbox-group.disabled > div.active {
  background: #757575;
  border-color: #757575;
  color: white;
}
.checkbox-group > div.active {
  background: #1890ff;
  border-color: #1890ff;
  color: white;
}
