.action .content {
  overflow: hidden;
}
.action .editor {
  border: 1px solid #f1f1f1;
  padding: 0 10px;
  min-height: 300px;
  max-height: 300px;
  overflow-y: scroll;
  transition: border 0.2s linear;
}
.action .editor-wrapper {
  max-width: 1200px;
}
/* Padding fix for toolbar buttons in content editor */
.action .editor-wrapper * {
  box-sizing: initial !important;
}
/* End padding fix */
.action .conditionGroupBtn button:first-child {
  cursor: move;
}
.action .filter_results { 
  margin-top: 15px;
  margin-bottom: -15px;
}
.action .condition_block {
  border: 1px dashed;
  border-radius: 3px;
  padding: 5px;
  margin: 5px 0;
}
.action .condition_block .condition_name {
  font-size: 12px;
  font-weight: 300;
}
.action .material-icons {
  cursor: pointer;
  color: #ccc;
  padding: 7.5px;
}
.action .material-icons.active {
  color: black;
}
.action .material-icons:hover {
  background-color: #F1F1F1;
}
.action .toolbar {
  padding: 5px 0;
  border-bottom: 3px solid #eee;
  display: flex;
  flex-wrap: wrap;
}
.action .toolbar .attribute_select {
  width: 125px;
  padding: 7.5px;
}
.action_toolbar_popup {
  max-width: 200px;
}
.action_toolbar_popup i {
  display: none;
}
.action_toolbar_popup input {
  margin-bottom: 5px;
}
.action .content_editor {
  padding: 10px 5px;
  min-height: 200px;
  border: 1px solid transparent;
}
.action .content_editor.isInside {
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
}

/* Temporarily hide popover icon until next major Ant Design release
  where they will introduce icon customisation */
.ant-popover-message > .anticon {
  display: none;
}
.ant-popover-message-title {
  padding-left: 0 !important;
}

/* Email component styles */
.action .email .schedule_button {
  margin-bottom: 10px;
}
.action .email .panel {
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  padding: 1em 2em;
  margin: 1em 0 2em;
  display: block;
  position: relative;
}
.action .email .panel .button {
  text-align: right;
}
.action .email .panel .button.floating {
  position: absolute;
  right: 25px;
  z-index: 1;
}
.action .email .preview {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
  min-height: 200px;
  margin: 15px 0;
  max-height: 325px;
  overflow-y: scroll;
}
.action .email .preview.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.action .email .panel.scheduler > div {
  margin-bottom: 0;
}
.action .email .panel.scheduler label {
  font-weight: 500;
}
.action .email .current_record {
  margin-left: 10px;
}
.action .checkbox {
  margin: -10px 0;
}
.action .field_label {
  display: inline;
}
.action .field_label i {
  margin-left: 5px;
}
.action .error {
  margin-top: 15px;
}

.action .email_history .ant-table-expanded-row:hover > td {
  background: #fbfbfb;
}
.view_email {
  display: flex;
  flex-wrap: wrap;
}
.view_email .field {
  width: 25%;
  font-weight: 700;
}
.view_email .value {
  width: 75%;
}
.view_email .email_content {
  padding: 10px;
  border: 1px solid #F1F1F1;
  width: 100%;
  max-height: 325px;
  overflow-y: scroll;
}

.action.is_feedback_form {
  margin-top: 25px;
}
.action .feedback {
  max-width: 750px;
}

.action .formlist_item > div {
  margin-bottom: 0px;
}
.action .formlist_item .ant-form-explain {
  display: none;
}
button.react-draggable {
  transition: none;
}
