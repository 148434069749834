.datasource .error {
  margin-top: 10px;
}
.datasource .info_tooltip {
  margin-left: 5px;
  cursor: help;
}
.datasource .bucket_policy {
  background: #eee;
  padding: 1em;
  margin: 0.5em 0;
  border: 1px dashed #ccc;
  border-radius: 5px;
  display: block;
}
.datasource .bucket_policy button {
  position: relative;
  float: right;
}
.datasource .bucket_policy pre {
  font-size: 0.75em;
  margin: 0;
  word-wrap: break-word;
}
.datasource .info {
  margin-bottom: 10px;
}

.datasource .file {
  padding: 10px;
  background: rgba(238, 238, 238, 0.5);
  margin-bottom: 10px;
  border-radius: 4px;
}
