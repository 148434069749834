.login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}
.login .content {
  display: flex;
  justify-content: center;
  margin: 25px;
}
.login .login_image {
  width: 500px;
  margin-right: 20px;
}
.login .tabs {  
  width: 300px;
}
.login .AAF {
  width: 200px;
}
.login i {
  color: rgba(0,0,0,.25);
}
.login button {
  width: 100%;
}
.login .error {
  margin-top: 20px;
}
.login .register {
  margin-bottom: 15px;
}
.login .register a {
  text-decoration: underline;
}
.login .demo_warning {
  max-width: 450px;
}