.login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1 1;
}
.login .content {
  display: flex;
  justify-content: center;
  margin: 25px;
}
.login .login_image {
  width: 500px;
  margin-right: 20px;
}
.login .tabs {  
  width: 300px;
}
.login .AAF {
  width: 200px;
}
.login i {
  color: rgba(0,0,0,.25);
}
.login button {
  width: 100%;
}
.login .error {
  margin-top: 20px;
}
.login .register {
  margin-bottom: 15px;
}
.login .register a {
  text-decoration: underline;
}
.login .demo_warning {
  max-width: 450px;
}
.container .info_icon {
  margin-right: 10px;
}
.container .ant-select-selection--multiple {
  max-height: 100px;
  overflow: auto;
}
.container .container_list .header_buttons {
  float: right;
  margin-right: 10px;
  margin-top: -5px;
}
.container .container_list .header_buttons button {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  margin-right: 10px;
}
.container .container_list .tab {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.container .container_list .filter_wrapper {
  width: 100%;
}
.container .container_list .filter {
  max-width: 500px;
  margin-bottom: 10px;
  display: flex;
}
.container .container_list .filter i {
  cursor: pointer;
}
.container .container_list .item {
  width: 175px;
  min-height: 175px;
  margin-right: 15px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.container .container_list .add {
  align-items: center;
  justify-content: center;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  background-color: #fafafa;
  cursor: pointer;
  padding: 15px;
  box-sizing: border-box;
  -webkit-transition: border 0.5s;
  transition: border 0.5s;
}
.container .container_list .add i {
  font-size: 32px;
  color: #999;
}
.container .container_list .add span {
  margin-top: 10px;
  color: #666;
}
.container .container_list .add:hover {
  border-color: #1890ff;
}
.container .container_list .clear {
  float: right;
}
.container .container_list .shared {
  color: #9e9e9e;
  margin-right: 10px;
}

.container .container_list .filter .ant-input-group input {
  border-radius: 0;
}

.datalab-drawer .ant-drawer-content-wrapper {
  width: 80% !important;
  max-width: 800px;
}
.ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
  background: #EEEEEE;
}

.container_list .cards .ant-row {
  display: flex;
  flex-wrap: wrap;
}
.datasource .error {
  margin-top: 10px;
}
.datasource .info_tooltip {
  margin-left: 5px;
  cursor: help;
}
.datasource .bucket_policy {
  background: #eee;
  padding: 1em;
  margin: 0.5em 0;
  border: 1px dashed #ccc;
  border-radius: 5px;
  display: block;
}
.datasource .bucket_policy button {
  position: relative;
  float: right;
}
.datasource .bucket_policy pre {
  font-size: 0.75em;
  margin: 0;
  word-wrap: break-word;
}
.datasource .info {
  margin-bottom: 10px;
}

.datasource .file {
  padding: 10px;
  background: rgba(238, 238, 238, 0.5);
  margin-bottom: 10px;
  border-radius: 4px;
}

/* Root component styles */
.dataLab .heading {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.dataLab .actions {
  margin-bottom: 20px;
}
.dataLab .name {
  max-width: 350px;
  margin-bottom: 20px;
}
.dataLab.is_web_form {
  margin-top: 25px;
}

/* Model component styles */
.dataLab .build {
  margin: 25px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.dataLab .build .module {
  margin: 0 25px 25px 0;
  position: relative;
}
.dataLab .module_toolbox {
  position: fixed;
  top: 0;
  right: 75px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.dataLab .module_toolbox .wrapper {
  background: #fafafa;
  padding: 15px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.dataLab .module_toolbox .wrapper > div {
  cursor: pointer;
  width: 90px;
  min-height: 90px;
}
.dataLab .module_toolbox .wrapper > div.dragging {
  cursor: move;
}
.dataLab .module_toolbox .module, 
.dataLab .add_module {
  width: 175px;
  min-height: 175px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 4px;
  padding: 5px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  color: #777;
}
.dataLab .module_toolbox .module i, 
.dataLab .add_module i {
  font-size: 24px;
}
.dataLab .module_toolbox .module span, 
.dataLab .add_module span {
  margin-top: 5px;
  font-size: 12px;
}
.dataLab .add_module {
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  -webkit-transition: border 0.5s;
  transition: border 0.5s;
}
.dataLab .module_toolbox .datasource, 
.dataLab .add_module.datasource {
  background-color: rgba(227,242,253, 0.20);
  border: 1px solid #BBDEFB;
}
.dataLab .module_toolbox .datasource i {
  color: #BBDEFB;
}
.dataLab .module_toolbox .form, 
.dataLab .add_module.form {
  background-color: rgba(237,231,246, 0.20);
  border: 1px solid #D1C4E9;
}
.dataLab .module_toolbox .form i {
  color: #D1C4E9;
}
.dataLab .module_toolbox .computed, 
.dataLab .add_module.computed {
  background-color: rgba(232,245,233, 0.20);
  border: 1px solid #C8E6C9;
}
.dataLab .module_toolbox .computed i {
  color: #C8E6C9;
}
.dataLab .module_toolbox .tracking_feedback, 
.dataLab .add_module.tracking_feedback {
  background-color: rgba(251, 233, 231, 0.20);
  border: 1px solid #FFCCBC;
}
.dataLab .module_toolbox .tracking_feedback i {
  color: #FFCCBC;
}
.dataLab .dragging {
  opacity: 0.35;
  border-style: dashed;
  cursor: move;
}

/* DataLab module styles */
.dataLab .module {
  width: 350px;
}
.dataLab .module .field {
  width: 100%;
}
.dataLab .module .error {
  color: #f5222d;
}
.dataLab .module .title {
  display: flex;
  align-items: center;
  width: 100%;
}
.dataLab .module .title .title_icon {
  font-size: 150%;
  margin-right: 5px;
}
.dataLab .module .title .step_number {
  position: absolute;
  top: -15px;
  left: -15px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Datasource module styles */
.dataLab .module .datasource {
  border-color: #BBDEFB;
}
.dataLab .module .datasource .title .step_number {
  background: #BBDEFB;
}
.dataLab .module .datasource .title .title_icon {
  color: #BBDEFB;
}
.dataLab .module .datasource .select i {
  display: none;
}
.dataLab .module .datasource .editing_field i {
  cursor: pointer;
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.65);
}
.dataLab .module .datasource .editing_field i:hover {
  color: #1890ff;
}
.dataLab .module .datasource .editing_input {
  display: flex;
}
.dataLab .module .datasource .editing_input .actions {
  display: flex;
  text-align: right;
}
.dataLab .module .datasource .normal_field i {
  opacity: 0;
  margin-left: 5px;
  -webkit-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out;
  font-weight: 300;
}
.dataLab .module .datasource .normal_field:hover i {
  opacity: 1;
}
/* Hide the tick icon that appears on the select option during editing mode */
.dataLab .module .datasource .editing_field:after {
  content: none !important;
  display: none !important;
}
/* Override the 'disabled' cursor on the select option during editing mode */
.dataLab .module .datasource .editing_field.ant-select-dropdown-menu-item-disabled {
  cursor: default;
  padding-right: 10px;
}
/* Darker background for the '...N more fields selected' tag */
.dataLab .module .datasource .select .ant-select-selection__choice__disabled {
  background: #E0E0E0;
}

/* Discrepencies modal styles */
.discrepencies .title {
  display: inline-flex;
  align-items: center;
}
.discrepencies .title i {
  margin-right: 5px;
  color: #faad14;
  font-size: 150%;
}
.discrepencies .conflict {
  position: relative;
}
.discrepencies .conflict .options {
  position: absolute;
  bottom: 20px;
}

/* Form module styles */
.dataLab .module .form {
  border-color: #D1C4E9;
}
.dataLab .module .form .title .step_number {
  background: #D1C4E9;
}
.dataLab .module .form .title .title_icon {
  color: #D1C4E9;
}
.dataLab .module .form .added_fields {
  font-size: 14px;
  margin: 8px 0;
}
.dataLab .module .form .form_tab {
  padding: 0 24px 24px;
}
.dataLab .module .form .web_form .field_label {
  margin-left: 5px;
}
.dataLab .web_form .single_record {
  max-width: 500px;
}
.dataLab .web_form .single_record .form_field {
  display: flex;
  margin: 5px 0;
}
.dataLab .web_form .single_record button {
  margin-top: 20px;
}
.dataLab .web_form .saved {
  background: #E8F5E9;
}
.form_export, .form_import {
  background: #eee;
  padding: 1em;
  margin: 0.5em 0;
  border: 1px dashed #ccc;
  border-radius: 5px;
  display: block;
  height: 300px;
  overflow-y: scroll;
  font-size: 0.75em;
  word-wrap: break-word;
  width: 100%;
}
.form_export button {
  position: absolute;
  right: 40px;
}
.form_export pre {
  margin: 0;
}
.form_import {
  font-family: Consolas, Menlo, Courier, monospace;
  resize: none;
}

/* Form field modal styles */
.formField .field_label,
.computed-field-modal .field_label {
  display: inline;
}
.formField .field_label i,
.computed-field-modal .field_label i {
  margin-left: 5px;
}
.formField .field {
  width: 100%;
}
.formField .option {
  display: flex;
  align-items: center;
}
.formField .option > input {
  width: 43%
}
.formField .option > *:not(:last-child) {
  margin-right: 5px;
}
/* Override the disabled select styles */
.dataLab .form .fields .ant-select-selection__choice,
.dataLab .computed .fields .ant-select-selection__choice { 
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
}
.dataLab .form .fields .ant-select-selection__choice:hover,
.dataLab .computed .fields .ant-select-selection__choice:hover { 
  border-color: #40a9ff;
}
.dataLab .form .fields > div,
.dataLab .computed .fields > div {
  background: white;
  cursor: default;
}

/* Computed module styles */
.dataLab .module .computed {
  border-color: #C8E6C9;
}
.dataLab .module .computed .title .step_number {
  background: #C8E6C9;
}
.dataLab .module .computed .title .title_icon {
  color: #C8E6C9;
}
.dataLab .module .computed .added_fields {
  font-size: 14px;
  margin: 8px 0;
}
/* Remove the border radius from the tree-select, so that it fits nicely
  inside the aggregate function creator */
.computed-field-modal .tree-select > span {
  border-radius: 0 2px 2px 0;
}
.computed-field-modal .delimiter > div {
  border-radius: 2px;
}
.computed-field-modal .toolbar {
  margin-bottom: 10px;
}

/* Data component styles */
.dataLab .data {
  position: relative;
}
.dataLab .filter {
  display: flex;
  align-items: center;
  margin: -10px 0px 15px 0px;
}
.dataLab .searchbar {
  width: 200px;
  margin: 0 10px;
}
.dataLab .data_manipulation .saved {
  background: #E8F5E9;
}
.dataLab .data_manipulation .column_header {
  display: inline;
}
.dataLab .data_manipulation .column_header.datasource {
  color: #2196F3;
}
.dataLab .data_manipulation .column_header.form {
  color: #9C27B0;
}
.dataLab .data_manipulation .column_header.computed {
  color: #66BB6A;
}
.dataLab .data_manipulation .menu_icon {
  margin-right: 5px;
}

/*  */
.dataLab .ant-form-item-control:not(.has-error) .fields-select.ant-select-disabled .ant-select-selection {
  border-color: #d9d9d9;
  box-shadow: none;
}


/* DataLab details (column order) interface */
.dataLab .details .from {
  display: flex;
  align-items: center;
}
.dataLab .details .from.computed {
  font-style: italic; 
}
.dataLab .details .from i {
  font-size: 125%;
  margin-right: 5px;
}
.dataLab .details .datasourceIcon {
  color: #BBDEFB;
}
.dataLab .details .formIcon {
  color: #D1C4E9;
}
.dataLab .details .computedIcon {
  color: #C8E6C9;
}
.dataLab .details  tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}
.dataLab .details  tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}



.dataLab .ant-form-item {
  margin: 0;
  width: 100%;
}

.dataLab .ant-select, .dataLab .ant-input-number, .dataLab .ant-calendar-picker {
  width: 100%;
}



.dataManipulation .form-field i:hover, .dataManipulation .editable-field i:hover {
  color: #40a9ff;
}
.dataManipulation .form-field .button {
  opacity: 0;
  -webkit-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out;
}
.dataManipulation .editable-field:hover .button,
.dataManipulation .form-field:hover .button {
  opacity: 1;
}
.dataManipulation .editable-field {
  display: flex;
  align-items: center;
}
.dataManipulation .editable-field .button, .dataManipulation .column-header-icons .button {
  margin-left: 5px;
}

/* The following are for ensuring that column headers stay on a single line */
.dataManipulation .ant-table-thead > tr > th {
  word-break: normal;
}
.dataManipulation .ant-table-thead > tr > th > span {
  display: flex;
  align-items: center;
}
.data_manipulation .ant-table-fixed {
  width: auto !important;
}

.field-design .ant-form-item {
  margin-bottom: 3px;
}
.field-design .option {
  display: flex;
  align-items: center;
}
.form-drawer .ant-drawer-content-wrapper {
  width: 80% !important;
  max-width: 1000px;
}

.dataLab .form .ant-select-disabled .ant-select-selection {
  background: none;
}

.dataLab .components {
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px;
  background: #fafafa;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
}
.dataLab .components > div:first-child {
  font-weight: 700;
  margin-bottom: 5px;
}
.dataLab .components button {
  padding: 0 11px !important;
}
.dataLab .components button.datasource {
  background: #e6f7ff;
  border-color: #91d5ff;
  color: #1890ff;
}
.dataLab .components button.computed {
  background: #f6ffed;
  border-color: #b7eb8f;
  color: #52c41a;
}
.dataLab .components button.form {
  background: #f9f0ff;
  border-color: #d3adf7;
  color: #722ed1;
}

.checkbox-group {
  display: flex;
}
.checkbox-group > div {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; 
  background: #fff;
  border: 1px solid #d9d9d9;
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
  -webkit-transition: color 0.3s, background 0.3s, border-color 0.3s;
  transition: color 0.3s, background 0.3s, border-color 0.3s;
}
.checkbox-group.disabled > div {
  cursor: default;
}
.checkbox-group.disabled > div.active {
  background: #757575;
  border-color: #757575;
  color: white;
}
.checkbox-group > div.active {
  background: #1890ff;
  border-color: #1890ff;
  color: white;
}

.querybuilder .field {
    border: 1px solid rgb(117, 117, 117);
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    background: rgb(250, 250, 250);
    margin: 2px 5px 2px 0px;
}
.querybuilder .field .name {
    padding: 0px 5px;
    cursor: default;
}
.querybuilder .field .name::after {
    content: ":";
    margin-left: 2px;
    position: relative;
    top: -0.5px;
}
.querybuilder .field .attributes { 
    display :flex;
}
.querybuilder .field .operation {
    min-width: 33px;
}
.querybuilder .field .operation div {
    border-radius: 0px;
}
.querybuilder .field .comparator {
    display: flex;
    align-items: center;
}
.querybuilder .field .comparator .and {
    padding: 0 5px;
}
.querybuilder .field .comparator > *,
.querybuilder .field .comparator input {
    border-radius: 0;
}
.querybuilder .field .comparator .field-input,
.querybuilder .field .comparator .field-input input {
    border-radius: 0 2px 2px 0;
}
.querybuilder .field .field-input {
    max-width: 150px;
    min-width: 60px;
}
.querybuilder .conditions {
    list-style: none;
    /* margin-top: -6px; */
    max-height: 200px;
    overflow-y: scroll;
}
.querybuilder .conditions li {
    position: relative;
}
.querybuilder .conditions li:not(:last-child) {
    margin-bottom: 10px;
}
.querybuilder .conditions .delete {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    left: -30px;
}
.querybuilder .parameters  {
    width: 100%;
    display: inline-block;
    padding: 7.5px;
    background: rgba(236,239,241, 0.75);
}
.querybuilder .conditions li.overlap .parameters {
    background: rgba(211,47,47, 0.20);
}
/* Override Ant Design styles so that field parameters (i.e. operator & comparator)
    can get a red border on error (via FormItem) but hide error text and extra margins */
.querybuilder .field .ant-form-item {
    margin-bottom: 0;
}
.querybuilder .field .ant-form-item-control {
    line-height: normal;
}
.querybuilder .field .ant-form-explain {
    display: none;
}
.action .content {
  overflow: hidden;
}
.action .editor {
  border: 1px solid #f1f1f1;
  padding: 0 10px;
  min-height: 300px;
  max-height: 300px;
  overflow-y: scroll;
  -webkit-transition: border 0.2s linear;
  transition: border 0.2s linear;
}
.action .editor-wrapper {
  max-width: 1200px;
}
/* Padding fix for toolbar buttons in content editor */
.action .editor-wrapper * {
  box-sizing: content-box !important;
  box-sizing: initial !important;
}
/* End padding fix */
.action .conditionGroupBtn button:first-child {
  cursor: move;
}
.action .filter_results { 
  margin-top: 15px;
  margin-bottom: -15px;
}
.action .condition_block {
  border: 1px dashed;
  border-radius: 3px;
  padding: 5px;
  margin: 5px 0;
}
.action .condition_block .condition_name {
  font-size: 12px;
  font-weight: 300;
}
.action .material-icons {
  cursor: pointer;
  color: #ccc;
  padding: 7.5px;
}
.action .material-icons.active {
  color: black;
}
.action .material-icons:hover {
  background-color: #F1F1F1;
}
.action .toolbar {
  padding: 5px 0;
  border-bottom: 3px solid #eee;
  display: flex;
  flex-wrap: wrap;
}
.action .toolbar .attribute_select {
  width: 125px;
  padding: 7.5px;
}
.action_toolbar_popup {
  max-width: 200px;
}
.action_toolbar_popup i {
  display: none;
}
.action_toolbar_popup input {
  margin-bottom: 5px;
}
.action .content_editor {
  padding: 10px 5px;
  min-height: 200px;
  border: 1px solid transparent;
}
.action .content_editor.isInside {
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
}

/* Temporarily hide popover icon until next major Ant Design release
  where they will introduce icon customisation */
.ant-popover-message > .anticon {
  display: none;
}
.ant-popover-message-title {
  padding-left: 0 !important;
}

/* Email component styles */
.action .email .schedule_button {
  margin-bottom: 10px;
}
.action .email .panel {
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  padding: 1em 2em;
  margin: 1em 0 2em;
  display: block;
  position: relative;
}
.action .email .panel .button {
  text-align: right;
}
.action .email .panel .button.floating {
  position: absolute;
  right: 25px;
  z-index: 1;
}
.action .email .preview {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
  min-height: 200px;
  margin: 15px 0;
  max-height: 325px;
  overflow-y: scroll;
}
.action .email .preview.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.action .email .panel.scheduler > div {
  margin-bottom: 0;
}
.action .email .panel.scheduler label {
  font-weight: 500;
}
.action .email .current_record {
  margin-left: 10px;
}
.action .checkbox {
  margin: -10px 0;
}
.action .field_label {
  display: inline;
}
.action .field_label i {
  margin-left: 5px;
}
.action .error {
  margin-top: 15px;
}

.action .email_history .ant-table-expanded-row:hover > td {
  background: #fbfbfb;
}
.view_email {
  display: flex;
  flex-wrap: wrap;
}
.view_email .field {
  width: 25%;
  font-weight: 700;
}
.view_email .value {
  width: 75%;
}
.view_email .email_content {
  padding: 10px;
  border: 1px solid #F1F1F1;
  width: 100%;
  max-height: 325px;
  overflow-y: scroll;
}

.action.is_feedback_form {
  margin-top: 25px;
}
.action .feedback {
  max-width: 750px;
}

.action .formlist_item > div {
  margin-bottom: 0px;
}
.action .formlist_item .ant-form-explain {
  display: none;
}
button.react-draggable {
  -webkit-transition: none;
  transition: none;
}

.ant-table-column-has-actions:hover .editable.is-not-editing {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
}
.ant-table-column-has-actions .editable.is-not-editing {
    line-height: 32px;
    padding: 0 11px;
}
.ant-table-column-has-actions:hover .editable.is-editing {
    padding: 0;
}
.saved {
  background: #E8F5E9;
}

.app {
  min-height: 100% !important;
}
.app .header {
  line-height: 64px;
  border-bottom: 1px solid #e8e8e8;
  background: #fff;
}
.app .header .logo {
  width: 120px;
  height: 31px;
  margin: 16px 28px 16px 0;
  float: left;
}
.app .logout {
  display: flex;
  align-items: center;
  height: 100%;
  float: right;
}
.app .navigation {
  line-height: 62px;
}
.app .footer {
  text-align: center;
}

.app .wrapper {
  margin-top: 25px;
  padding: 0 50px;
}
.app .layout {
  padding: 24px 0;
  background: #fff;
}
.app .content {
  padding: 0 24px;
  min-height: 280px;
}
.app .content_body {
  background: #fff;
}
.app .content .heading a {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

/* Hide the warning text for a given field in an Ant Design form */
.no-explain .ant-form-explain {
  display: none;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.17rem;
}
* {
  box-sizing: border-box;
  outline: 0;
}

body {
  margin: 0;
  padding: 0;
}

body, #root {
  height: 100%;
  width: 100%;
}

/* Border bug fix for ant design sider */
.ant-layout-sider {
  background: none;
}
.ant-layout-sider-children {
  margin-top: 0;
}

/* Capitalize drop-down menu headers */
.ant-select-dropdown-menu-item-group-title {
  color: #1890ff;
}
